import PropTypes from "prop-types";
import React, { useEffect, useRef,  useState } from "react";
import Iconfiy from 'pages/Utility/Iconfiy'

// //Import Scrollbar
import SimpleBar from "simplebar-react";


// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";


//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const [userDetails, setUserDetails] = useState("")





  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  useEffect(() => {
      if(localStorage.getItem("profile")){
        const obj = JSON.parse(localStorage.getItem("profile"))
        setUserDetails(obj)
      }
  }, [])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  function closeSidebar() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.remove("sidebar-enable");
    } else {
      body.classList.remove("vertical-collpsed");
      body.classList.remove("sidebar-enable");
    }
  }

  return (
    <React.Fragment>
       <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <>
              <li>
                <Link to="/dashboard" className="" onClick={closeSidebar}>
                  <Iconfiy icon="mdi:view-dashboard"/>
                  <span style={{ marginLeft: "5px"}}>{props.t("Dashboard")}</span>
                </Link>
              </li>

              <li>
                <Link to="/overtwofivecalculator" className="" onClick={closeSidebar}>
                  <Iconfiy icon="mdi:calculator"/>
                  <span style={{ marginLeft: "5px"}}>{props.t("Over 2.5 Calculator")}</span>
                </Link>
              </li>

              <li>
                <Link to="/stake-calculator" className="" onClick={closeSidebar}>
                  <Iconfiy icon="solar:calculator-minimalistic-broken"/>
                  <span style={{ marginLeft: "5px"}}>{props.t("Stake Calculator")}</span>
                </Link>
              </li>

              <li>
                <Link to="/signal-4" className="" onClick={closeSidebar}>
                  <Iconfiy icon="mynaui:mobile-signal-five"/>
                  <span style={{ marginLeft: "5px"}}>{props.t("Over 4.5 signal")}</span>
                </Link>
              </li>

             

              <li>
                <Link to="/punters-hub" className="" onClick={closeSidebar}>
                  <Iconfiy icon="material-symbols-light:casino-outline"/>
                  <span style={{ marginLeft: "5px"}}>{props.t("Punters Hub")}</span>
                </Link>
              </li>

              {/* <li>
                <Link to="/virtual-analyst" className="" onClick={closeSidebar}>
                  <Iconfiy icon="material-symbols-light:casino-outline"/>
                  <span style={{ marginLeft: "5px"}}>{props.t("Virtual Analyst")}</span>
                </Link>
              </li> */}

              <li>
                <Link to="/how-to" className="" onClick={closeSidebar}>
                  <Iconfiy icon="ph:video-light"/>
                  <span style={{ marginLeft: "5px"}}>{props.t("How to")}</span>
                </Link>
              </li>
            </>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
